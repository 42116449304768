import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Page, Card, EmptyState } from "@shopify/polaris";
import { useAppBridge, TitleBar } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";
import { navigate, PageProps } from "gatsby";
import useQueryParam from "../lib/useQueryParam";
import AppContextConsumer from "../layouts/AppContext";

interface InstallState {
  url?: string;
  backUrl?: string;
  type?: string;
}
// interface InstallPageProps extends PageProps<null,null, InstallState>
interface InstallPageContext {
  shop: string;
  host: string;
}
type InstallPageProps = PageProps<null, InstallPageContext, InstallState>;

const installPage: React.FC<InstallPageProps> = (props) => {
  const { location, pageContext } = props;
  const app = useAppBridge();
  const redirect = Redirect.create(app);
  // const [shop] = useQueryParam("shop", null);
  const [installUrl] = useState(location.state.url);
  const [backUrl] = useState(location.state.backUrl);
  const [installType] = useState(location.state.type || "install");
  const redirectTpShopifyInstall = useCallback(
    (data: any) => {
      // const url = `https://${shop}/draft_orders/${id}`;
      const url = new URL(installUrl);
      const { pathname, search } = url;
      const relativePathname = pathname.replace(/^\/admin/i, "");
      const path = `${relativePathname}${search}`;
      // console.log({ data, path });
      redirect.dispatch(Redirect.Action.ADMIN_PATH, {
        path,
      });
    },
    [installUrl]
  );
  return (
    <Page
      breadcrumbs={
        backUrl
          ? [
              {
                content: "Back",
                onAction: () => navigate(backUrl),
              },
            ]
          : null
      }
    >
      <TitleBar title="Install" />
      <AppContextConsumer>
        {({ data }) => {
          return (
            <Card sectioned>
              <EmptyState
                heading="Click the button"
                // action={{ content: "Install", url: installUrl }}
                action={{
                  content: installType === "update" ? "Update" : "Install",
                  onAction: () => redirectTpShopifyInstall(data),
                }}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                fullWidth
              >
                {installType === "update" ? (
                  <p>App updated needs refreshed permissions</p>
                ) : (
                  <p>Install this thing to make it work...</p>
                )}
              </EmptyState>
            </Card>
          );
        }}
      </AppContextConsumer>
      {/* {JSON.stringify({ state: location.state, pageContext })} */}
    </Page>
  );
};

export default installPage;
